@media print {
  @page {
    size: A4 vertical;
    margin: 0%;
    -webkit-print-color-adjust: exact !important;
  }

  * {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  html,
  body,
  #body,
  .body {
    height: auto !important;
    overflow: unset !important;
    font-size: 15px !important;
  }

  .frame {
    &>.container {
      padding: 0 15px;
    }

    .landing {
      padding: 20px;
      display: flex;
      align-items: center;
      column-gap: 20px;

      .propic {
        flex-shrink: 0;
        height: 100px;
        width: 100px;
        border-radius: 100%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: 0px -3px;
        }
      }

      .dati {
        h1 {
          font-size: 30px;
          margin: 0 0 10px;
        }

        .contatti {
          display: flex;
          flex-wrap: wrap;
          column-gap: 15px;
          row-gap: 10px;
          align-items: center;

          .single {
            display: flex;
            column-gap: 8px;
            align-items: center;

            i {
              font-size: 18px;
            }
          }
        }
      }
    }

    .chart-100,
    .chart-75,
    .chart-25 {
      width: 100px;
      height: 100px;

      svg {

        .circle-background,
        .circle-foreground {
          r: 42.5px;
        }
      }
    }

    .chart-75 svg .circle-foreground {
      stroke-dasharray: 200.175px 266.9px;
      stroke-dashoffset: 200.175px;
    }

    .chart-25 svg .circle-foreground {
      stroke-dasharray: 66.725px 266.9px;
      stroke-dashoffset: 66.725px;
    }

    figcaption {
      font-size: 18px !important;
    }

    h3 {
      margin: 0 0 10px !important;
      padding: 20px 0 0 !important;
      font-size: 25px !important;
      font-weight: bold !important;

      &#Design {
        margin-top: 20px !important;
        text-align: center;

        &+.row {
          h4 {
            margin-bottom: 10px;
          }
        }
      }
    }

    h4 {
      font-size: 20px !important;
    }

    .h-line {
      &:nth-child(2) {
        margin-top: 20px !important;
      }

      &:not(:nth-child(2)) {
        padding-top: 20px !important;
      }

      padding-bottom: 10px !important;

      .where,
      .cursus {
        font-size: 16px !important;
      }
    }

    .print_nowrap {
      display: flex !important;
      flex-wrap: nowrap !important;
      column-gap: 15px;

      .design {
        align-items: center;
      }
    }
  }

  .no-print,
  .hobbies,
  .button.print,
  footer,
  .go-top,
  .navbar {
    display: none !important;
  }
}